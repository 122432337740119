import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  z-index: 1000;
  margin-top: -50px;
`;

export const DateTextWrapper = styled.div`
  display: inline-flex;
  gap: 12px;
`;

export const CalendarIcon = styled.i`
  cursor: pointer;
  color: #000;
  font-size: 18px;
`;
