import 'react-datepicker/dist/react-datepicker.css';

import React, {useState, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import ediErrorCodeHandler from '../../../../lib/ediErrorCodeHandler';
import DatePicker from 'react-datepicker';
import {parse, isValid} from 'date-fns';
import {sl, ro, bg, enUS} from 'date-fns/locale';
import {CalendarIcon, Container, DateTextWrapper} from './DeliveryDate.style';

const getLocale = (lang) => {
  switch (lang) {
    case 'sl':
      return sl;
    case 'bg':
      return bg;
    case 'ro':
      return ro;
    default:
      return enUS;
  }
};

/**
 * Hook that alerts clicks outside of the passed ref
 */
function useOutsideAlerter(ref, setDatepickerOpen) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setDatepickerOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, setDatepickerOpen]);
}

const DeliveryDate = ({
  availableDate,
  minimumDate,
  inquiryError,
  onDateSelected,
}) => {
  const {t, i18n} = useTranslation();
  const error = ediErrorCodeHandler(inquiryError, t);
  const [datepickerOpen, setDatepickerOpen] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef, setDatepickerOpen);

  // get locale
  const lang = i18n.language;
  // 13.3.2025
  let parsedDate = null;
  if (minimumDate) {
    parsedDate = parse(minimumDate, 'dd.MM.yyyy', new Date());
    if (!isValid(parsedDate)) {
      parsedDate = null;
    }
  }

  const onChange = (date) => {
    onDateSelected(date);
    setDatepickerOpen(false);
  };

  if (error) {
    return error;
  }

  if (availableDate === null) {
    return t('notInStock');
  }

  return datepickerOpen ? (
    <Container ref={wrapperRef}>
      <DatePicker
        selected={parsedDate}
        onChange={onChange}
        minDate={parsedDate}
        // disable weekends
        filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
        locale={getLocale(lang)}
        inline
      />
    </Container>
  ) : (
    <DateTextWrapper>
      <span>{availableDate}</span>
      {availableDate && (
        <CalendarIcon
          className="mdi mdi-calendar"
          onClick={() => {
            setDatepickerOpen(true);
          }}
        />
      )}
    </DateTextWrapper>
  );
};

export default DeliveryDate;
