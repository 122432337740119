import {Component} from 'react';
import {connect} from 'react-redux';

// alertify
import * as alertify from '../../components/Alerts/lib/alertify';

// actions
import {setCart} from '../../redux/actions';

// components
import CartContentTable from '../../components/CartContentTable/CartContentTable';
import DisplayCartData from '../../components/DisplayCartData/DisplayCartData';
import RemoveFromCart from '../../components/RemoveFromCart/RemoveFromCart';
import Content from '../../components/SearchContainer/SearchContainer';
import Table from '../../components/Table/Table';
import getItemsFromCart from '../../lib/getItemsFromCart';
import removeItemFromCart from '../../lib/removeItemFromCart';
import CheckBox from '../../components/CheckBox/CheckBox';
import TextDiv from '../../components/TextDiv/TextDiv';
import Checkout from '../../components/Checkout/Checkout';

// data
import columns from './data/columns';

// libs
import {addItemsToCart} from '../../lib/addItemToCart';
import getNextWorkingDay from '../../order/libs/getNextWorkingDay.order';
import {formatPriceWithCurrency} from '../../lib/formatPriceWithCurrency';

// api
import orderApi from '../../order/api/order.api.order';

// error message
import getErrorMessageFromErrorCode from '../../lib/getErrorMessageFromErrorCode';
import {withTranslation} from 'react-i18next';

class CartContainer extends Component {
  static propTypes = {};

  state = {
    items: [],
    itemsCount: 0,
    total: 0,
    allSelected: false,
    comment: '',
    orderNo: '',
    disabled: true,
  };

  componentDidMount() {
    this.mounted = true;
    this.getCartItems();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getCartItems = () => {
    const items = getItemsFromCart();
    this.countCartItems(items);
    this.calculateCartTotal(items);
    this.setState({items});
  };

  countCartItems = (items) => {
    const itemsCount =
      items && items.length > 0
        ? items
            .map((item) => (item.cartQuantity ? item.cartQuantity : 0))
            .reduce((prev, next) => prev + next)
        : 0;

    this.setState({itemsCount});
  };

  calculateCartTotal = (items) => {
    let total = 0;
    items.forEach((item) => {
      total += (item.cartQuantity ? item.cartQuantity : 0) * item.grossPrice;
    });
    total = total.toFixed(2);
    this.setState({total});
  };

  onRemove = (id) => {
    const items = removeItemFromCart(id);
    this.countCartItems(items);
    this.calculateCartTotal(items);
    this.setState({items});
    this.props.dispatch(setCart(items));
  };

  onSelect = (id) => {
    const items = [...this.state.items];
    const item = items.find((i) => i._id === id);
    item.selected = item.selected ? false : true;
    const index = items.findIndex((i) => i._id === id);
    items[index] = item;
    this.setState({items});
  };

  onSelectAll = () => {
    const allSelected = this.state.allSelected;
    const items = [...this.state.items].map((item) => {
      item.selected = !allSelected;
      return item;
    });
    this.setState({items, allSelected: !allSelected});
  };

  onRemoveSelected = () => {
    const items = [...this.state.items].filter(
      (item) => item.selected !== true
    );
    this.setState({items});
    addItemsToCart(items);
    this.countCartItems(items);
    this.calculateCartTotal(items);
    this.props.dispatch(setCart(items));
  };

  onCommentChange = (comment) => {
    this.setState({comment});
  };

  onOrderNumberChange = (orderNo) => {
    this.setState({
      orderNo,
      disabled: orderNo ? false : true,
    });
  };

  onSubmit = async () => {
    this.setState({
      disabled: true,
    });
    const tyres = [...this.state.items];
    const documentID = this.state.orderNo;
    const comment = this.state.comment;
    const t = this.props.t;

    try {
      const req = await orderApi({
        order: {
          documentID,
          comment,
          orderLines: tyres.map((tyre) => ({
            catalogNumber: tyre.catalogNumber,
            rowQuantity: tyre.cartQuantity,
            confirmedDeliveryDate: tyre.deliveryDate
              ? tyre.deliveryDate
              : getNextWorkingDay(),
          })),
        },
      });
      const status = Number(req.contiOrder.status);
      if (status < 1) {
        alertify.success(t('orderCreated'));
        this.setState({
          items: [],
          disabled: false,
        });
        addItemsToCart([]);
        this.countCartItems([]);
        this.calculateCartTotal([]);
        this.props.dispatch(setCart(null));
      } else {
        const errorMessage = getErrorMessageFromErrorCode(status, t);
        alertify.error(errorMessage);
      }
    } catch (error) {
      alertify.error(t('errorHappened'));
      this.setState({
        disabled: false,
      });
    }
  };

  render() {
    const {items, itemsCount, disabled, total, comment, orderNo, allSelected} =
      this.state;
    const t = this.props.t;
    return (
      <Content>
        <DisplayCartData
          cartItemsCount={itemsCount}
          cartTotalPrice={total}
        ></DisplayCartData>
        <CartContentTable>
          <Table
            loading={false}
            columns={columns(t)}
            size="medium"
            headerSize="small"
            noMargin={false}
            labelSpace={false}
            onSort={this.onSort}
            theme="white"
            noDataContent={t('noItemsInCart')}
          >
            {(TableRow) =>
              items.map((row) => (
                <TableRow
                  key={row._id}
                  id={row._id}
                  noMargin={false}
                  clickable={false}
                >
                  {(TableCol) => [
                    <TableCol key="check" span={1}>
                      <CheckBox
                        selected={row.selected}
                        onSelect={() => this.onSelect(row._id)}
                      ></CheckBox>
                    </TableCol>,
                    <TableCol key="catalogNo" span={2}>
                      {row.catalogNumber}
                    </TableCol>,
                    <TableCol key="name" span={3}>
                      {row.name}
                    </TableCol>,
                    <TableCol key="quantity" span={2}>
                      <TextDiv align="center">{row.cartQuantity}</TextDiv>
                    </TableCol>,
                    <TableCol key="price" span={2}>
                      <TextDiv align="center">
                        {formatPriceWithCurrency(
                          (row.grossPrice * row.cartQuantity).toFixed(2)
                        )}
                      </TextDiv>
                    </TableCol>,
                    <TableCol key="confirmedQuantity" span={2}>
                      <TextDiv
                        color="black"
                        weight="bold"
                        bgColor={
                          row.confirmedDeliveryDate ? 'lightGreen' : 'red'
                        }
                        align="center"
                      >
                        {row.cartQuantity}
                      </TextDiv>
                    </TableCol>,
                    <TableCol key="deliveryDate" span={2}>
                      <TextDiv align="center">
                        {row.confirmedDeliveryDate
                          ? row.confirmedDeliveryDate
                          : t('notInStock')}
                      </TextDiv>
                    </TableCol>,
                    <TableCol span={1} key="cart">
                      <RemoveFromCart id={row._id} onClick={this.onRemove} />
                    </TableCol>,
                  ]}
                </TableRow>
              ))
            }
          </Table>
        </CartContentTable>
        {items.length > 0 ? (
          <Checkout
            allSelected={allSelected}
            comment={comment}
            orderNo={orderNo}
            disabled={disabled}
            onSelectAll={this.onSelectAll}
            onRemoveSelected={this.onRemoveSelected}
            onCommentChange={this.onCommentChange}
            onOrderNumberChange={this.onOrderNumberChange}
            onSubmit={this.onSubmit}
          />
        ) : (
          ''
        )}
      </Content>
    );
  }
}

export default connect()(withTranslation()(CartContainer));
