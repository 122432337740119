import {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {v4} from 'uuid';

// alertify
import * as alertify from '../../components/Alerts/lib/alertify';

// actions
import {search as searchAct} from '../../search/redux/actions';
import {seasons as seasonsAct} from '../../seasons/redux/actions';
import {brand as brandAct} from '../../brand/redux/actions';
import {chassis as chassisAct} from '../../chassis/redux/actions';
import {vehicleModel as vehicleModelAct} from '../../vehicle/redux/actions';

// components
import InfiniteList from '../../layout/components/InfiniteList/InfiniteList';
import SearchContent from '../../components/SearchContainer/SearchContainer';
import Table from '../../components/Table/Table';
import SearchFilters from '../../components/SearchFilters/SearchFilters';
import TyrePropertiesWithIcons from '../../components/TyrePropertiesWithIcons/TyrePropertiesWithIcons';
import CatalogNumberWithLogo from '../../components/CatalogNumberWithLogo/CatalogNumberWithLogo';
import AddToCart from '../../components/AddToCart/AddToCart';
import AdvancedSearchFilters from '../../components/AdvancedSearchFilters/AdvancedSearchFilters';
import ExtendedTableRow from '../../components/ExtendedTableRow/ExtendedTableRow';
import EmailButton from '../../components/EmailButton/EmailButton';

// columns
import columns from './data/columns';

// error
import parseError from '../../error/parseError';

// lib
import {format, parse} from 'date-fns';
import addItemToCart from '../../lib/addItemToCart';
import seasonButtons from '../../consts/seasonButtons';

// actions
import {setCart} from '../../redux/actions';

// api
import getTyresApi from '../../tyre/api/list.api.tyre';
import inquiryApi from '../../inquiry/api/inquiry.api.inquiry';
import listBrandsApi from '../../brand/api/list.api.brand';
import getSeasonsApi from '../../seasons/api/season.api.season';

// routes
import routeCartPage from '../../pages/CartPage/route';

// libs
import createMail from './createMail';
import SeasonIcon from '../../components/SeasonIcon/SeasonIcon';
import ediErrorCodeHandler from '../../lib/ediErrorCodeHandler';
import capitalize from '../../lib/capitalize';

// translations
import {withTranslation} from 'react-i18next';
import {formatPriceWithCurrency} from '../../lib/formatPriceWithCurrency';

class SearchContainer extends Component {
  static propTypes = {
    search: PropTypes.string,
    dispatch: PropTypes.func,
    history: PropTypes.object,
  };

  static PER_PAGE = 30;

  state = {
    loading: false,
    addToCartLoadingId: '',
    tyres: [],
    sort: null,
    more: true,
    page: 0,
    searchOnlyInStock: false,
    search: '',
    advancedSearch: false,
    advancedSearchFilters: {
      brand: '',
      type: '',
      name: '',
      ean: '',
      dimensions: '',
    },
    brands: [],
    seasonButtons: [...seasonButtons(this.props.t)],
    chassis: this.props.chassis,
    vehicleModel: this.props.vehicleModel,
  };

  API_ID = null;

  componentDidMount() {
    this.mounted = true;
    this.checkIfBrandSelected();
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getTyres = async ({
    page = this.state.page,
    search = this.props.search,
    sort = this.state.sort,
    refresh = false,
  }) => {
    const API_ID = v4();
    this.API_ID = API_ID;

    const q = {page, search, sort};
    const state = {
      loading: true,
      search,
      sort,
      ...q,
    };
    if (refresh) state.tyres = [];
    this.setState(state);

    try {
      const results = await getTyresApi(this.query(q));
      if (!this.mounted || API_ID !== this.API_ID) return;
      this.setState({
        tyres: [...(refresh ? [] : this.state.tyres), ...results],
        loading: false,
        more: results.length >= this.constructor.PER_PAGE,
      });
    } catch (error) {
      if (!this.mounted || API_ID !== this.API_ID) return;
      this.setState({loading: false, tyres: [], page: 1, more: false});
    }
  };

  getBrands = async () => {
    const brands = [...this.state.brands];
    const selectedBrand = this.props.brand;
    const t = this.props.t;

    if (brands && brands.length > 0) return;
    try {
      const apiBrands = await listBrandsApi();
      const brands = apiBrands.map((brand) => ({
        label: capitalize(brand.name),
        value: brand._id,
      }));
      brands.unshift({
        label: t('all'),
        value: 'null',
      });
      this.onAdvancedFiltersChange('brand', selectedBrand);
      this.setState({
        brands,
      });
    } catch (error) {
      alertify.error(t('somethingWrong'));
    }
  };

  getSeasons = async () => {
    const seasonButtons = [...this.state.seasonButtons];
    const selectedSeasons = this.props.seasons;
    try {
      const apiSeasons = await getSeasonsApi();

      const seasonBtns = seasonButtons.map((t) => {
        const seasonId = apiSeasons.find((s) => s.name === t.name)._id;
        t._id = seasonId;
        t.selected = selectedSeasons.includes(seasonId);
        return t;
      });

      this.setState({
        seasonButtons: seasonBtns,
      });
    } catch (error) {}
  };

  createInquiryApi = async (tyre, requestedDeliveryDay = Date.now()) => {
    const formattedToday = format(requestedDeliveryDay, 'yyyy-MM-dd');

    try {
      const item = await inquiryApi({
        inquiry: {
          orderLines: [
            {
              catalogNumber: tyre.catalogNumber,
              rowQuantity: tyre.cartQuantity ? tyre.cartQuantity : 4,
              requestedDeliveryDate: formattedToday,
            },
          ],
        },
      });
      // error
      const deliveryDate = format(new Date(item.deliveryDate), 'dd.MM.yyyy');
      return deliveryDate === '31.12.9999'
        ? {deliveryDate: null, error: item.error}
        : {deliveryDate, error: item.error};
    } catch (error) {
      return {error: parseError(error).message, deliveryDate: null};
    }
  };

  checkIfBrandSelected = async () => {
    const brand = this.props.brand;
    if (brand) {
      await this.getBrands();
      this.setState({
        advancedSearch: true,
      });
      this.getTyres({refresh: true, page: 1});
    }
  };

  query = ({
    search = this.props.search,
    seasons = this.props.seasons,
    page = this.state.page,
    sort = this.state.sort,
    perPage = this.constructor.PER_PAGE,
    advancedSearch = this.state.advancedSearch,
    searchOnlyInStock = this.state.searchOnlyInStock,
    chassis = this.state.chassis,
    vehicleModel = this.state.vehicleModel,
  } = {}) => {
    const query = {
      limit: perPage,
      available: true,
      offset: (page - 1) * perPage,
      populate: 'brand, season',
    };
    if (!!search.trim().length)
      query['search'] = `${
        query['search'] ? `${query['search']}||` : ''
      }catalogNumber__icontains:${search.trim()}||dimensions__icontains:${search.trim()}`;

    if (seasons.length > 0) {
      query['season__isin'] = seasons.join(',');
    }

    if (!!sort)
      query.order = `${sort.direction === 'asc' ? '' : '-'}${sort.key}`;
    if (advancedSearch) {
      const advancedSearchFilters = {...this.state.advancedSearchFilters};
      Object.entries(advancedSearchFilters).forEach(([key, value]) => {
        if (value && value !== 'null') {
          // query[`${key}${key === 'brand' ? '' : '__icontains'}`] = value;
          query[key] = value;
        }
      });
    }

    if (searchOnlyInStock) {
      query['quantity__gt'] = '0';
    }
    if (chassis) {
      query['vehicleVin'] = chassis;
    }
    if (vehicleModel) {
      query['vehicleModel'] = vehicleModel;
    }
    return query;
  };

  onSearchOnlyInStockClick = () => {
    const {searchOnlyInStock} = this.state;
    this.setState(
      {
        searchOnlyInStock: !searchOnlyInStock,
      },
      () => this.getTyres({refresh: true, page: 1})
    );
  };

  onSearchValueChange = (search) => {
    this.props.dispatch(searchAct(search.replace(/\s/g, '')));
  };

  onSearchSubmit = () => {
    const {search} = this.props;
    this.getTyres({search, refresh: true, page: 1});
  };

  onSort = (sort) => this.getTyres({sort, refresh: true, page: 1});

  onQuantityChange = (id, value) => {
    // const val = value && value > 40 ? 40 : value;
    const val = value
      ? value < 1
        ? 1
        : Math.round(value) > 40
        ? 40
        : Math.round(value)
      : null;
    const tyres = [...this.state.tyres];
    tyres.find((d) => d._id === id).cartQuantity = val;
    this.setState({tyres});
  };

  onAddToCart = async (id) => {
    this.setState({
      addToCartLoadingId: id,
    });
    const tyres = [...this.state.tyres];
    const t = this.props.t;
    const tyre = tyres.find((t) => t._id === id);
    try {
      const deliveryDate = tyre.deliveryDate
        ? parse(tyre.deliveryDate, 'dd.MM.yyyy', new Date())
        : new Date();
      const inquiry = await this.createInquiryApi(tyre, deliveryDate);
      const getError = ediErrorCodeHandler(inquiry.error, t);
      if (getError !== null) throw getError;
      tyre.confirmedDeliveryDate = inquiry.deliveryDate;
      const cartItems = addItemToCart(tyre);
      this.props.dispatch(setCart(cartItems));
      this.setState({
        addToCartLoadingId: null,
      });
      alertify.success(t('addedToCart'));
      this.props.history.push(routeCartPage());
    } catch (error) {
      this.setState({
        addToCartLoadingId: null,
      });
      alertify.error(error);
    }
  };

  loadMore = () => {
    const {loading, page, more} = this.state;
    if (loading || !more) return;
    this.getTyres({page: page + 1});
  };

  onAdvancedSearchClick = async () => {
    const advancedSearch = this.state.advancedSearch;
    await this.getBrands();
    await this.getSeasons();

    this.setState({
      advancedSearch: !advancedSearch,
    });
  };

  onAdvancedFiltersChange = (key, value) => {
    if (key === 'brand') {
      this.props.dispatch(brandAct(value));
    }
    const advancedSearchFilters = {...this.state.advancedSearchFilters};
    advancedSearchFilters[key] = value;
    this.setState({advancedSearchFilters});
  };

  onAdvancedSearchSubmit = async ({chassis, vehicleModel, vehicleMake}) => {
    this.setState(
      {
        chassis,
        vehicleModel,
      },
      async () => {
        await this.getTyres({
          page: 1,
          refresh: true,
        });
        this.props.dispatch(chassisAct(chassis));
        this.props.dispatch(vehicleModelAct(vehicleMake, vehicleModel));
      }
    );
  };

  onClearFilters = () => {
    this.props.dispatch(seasonsAct([]));
    this.props.dispatch(searchAct(''));
    this.props.dispatch(chassisAct(''));
    this.props.dispatch(vehicleModelAct('', ''));

    const seasonButtons = [...this.state.seasonButtons];
    this.setState({
      advancedSearchFilters: {
        brand: '',
        type: '',
        name: '',
        rimSize: '',
        width: '',
        profile: '',
      },
      seasonButtons: seasonButtons.map((s) => {
        s.selected = false;
        return s;
      }),
      search: '',
    });
  };

  onSeasonButtonClick = (id) => {
    const selectedSeasonButtons = [...this.state.seasonButtons];
    const tyreBtn = selectedSeasonButtons.find((t) => t.id === id);
    selectedSeasonButtons.find((t) => t.id === id).selected = !tyreBtn.selected;
    this.setState(
      {
        selectedSeasonButtons,
        seasonButtons: selectedSeasonButtons,
      },
      () => {
        this.props.dispatch(
          seasonsAct(
            selectedSeasonButtons
              .filter((s) => s.selected === true)
              .map((s) => s._id)
          )
        );
      }
    );
  };

  rowClicked = async (id, open) => {
    if (!open) return;
    const tyres = [...this.state.tyres];
    const tyre = [...tyres].find((t) => t._id === id);
    if (!tyre) return;
    try {
      const inquiry = await this.createInquiryApi(tyre);
      const updatedTypes = [...tyres].map((tyreToUpdate) =>
        tyreToUpdate._id === tyre._id
          ? {
              ...tyreToUpdate,
              deliveryDate: inquiry.deliveryDate,
              inquiryError: inquiry.error,
              minimumDate: inquiry.deliveryDate,
            }
          : tyreToUpdate
      );
      this.setState({tyres: updatedTypes});
    } catch (error) {
      console.error(error);
    }
  };

  emailClicked = (id) => {
    const tyres = [...this.state.tyres];
    const tyre = tyres.find((t) => t._id === id);
    createMail(tyre);
  };

  getSeasonIcon = (name) => {
    const seasonButtons = [...this.state.seasonButtons];
    const season = seasonButtons.find((s) => s.name === name);
    return {
      icon: season.icon,
      color: season.color,
    };
  };

  onRequestedDeliveryDateChange = async ({date, tyre}) => {
    // create inquiry
    try {
      const inquiry = await this.createInquiryApi(tyre, date);
      const updatedTypes = this.state.tyres.map((tyreToUpdate) =>
        tyreToUpdate._id === tyre._id
          ? {
              ...tyreToUpdate,
              deliveryDate: inquiry.deliveryDate,
              inquiryError: inquiry.error,
            }
          : tyreToUpdate
      );
      this.setState({tyres: updatedTypes});
    } catch (error) {
      console.error(error);
    }
  };

  render() {
    const {search} = this.props;
    const {
      brands,
      tyres,
      sort,
      loading,
      addToCartLoadingId,
      more,
      advancedSearch,
      advancedSearchFilters,
      seasonButtons,
      searchOnlyInStock,
    } = this.state;
    return (
      <SearchContent>
        <SearchFilters
          value={search}
          onInputChange={this.onSearchValueChange}
          onSearch={this.onSearchSubmit}
          searchOnlyInStock={searchOnlyInStock}
          onSearchOnlyInStockClick={this.onSearchOnlyInStockClick}
        />
        <AdvancedSearchFilters
          brands={brands}
          advancedSearch={advancedSearch}
          advancedSearchFilters={advancedSearchFilters}
          onAdvancedFiltersChange={this.onAdvancedFiltersChange}
          onAdvancedSearchClick={this.onAdvancedSearchClick}
          onAdvancedSearchSubmit={this.onAdvancedSearchSubmit}
          clearFilters={this.onClearFilters}
          seasonButtons={seasonButtons}
          onSeasonButtonClick={this.onSeasonButtonClick}
        />
        <InfiniteList onLoad={this.loadMore} hasMore={more}>
          {loading && !tyres.length ? (
            ''
          ) : (
            <Table
              columns={columns(this.props.t)}
              size="medium"
              headerSize="large"
              noMargin={false}
              labelSpace={false}
              onSort={this.onSort}
              sort={sort}
              theme="white"
              noDataContent={this.props.t('noResults')}
            >
              {(TableRow) =>
                tyres.map((row) => (
                  <TableRow
                    key={row._id}
                    id={row._id}
                    noMargin={false}
                    clickable={true}
                    rowClick={this.rowClicked}
                    dropdown={
                      <ExtendedTableRow
                        netPrice={row.netPrice}
                        discountGrossPrice={row.discountGrossPrice}
                        availabilityDate={row.deliveryDate}
                        inquiryError={row.inquiryError}
                        ean={row.ean}
                        load={row.loadRating}
                        depth={row.depth}
                        design={row.design}
                        tyre={row}
                        onRequestedDeliveryDateChange={
                          this.onRequestedDeliveryDateChange
                        }
                      />
                    }
                  >
                    {(TableCol) => [
                      <TableCol key="catalogNo" span={3}>
                        <>
                          <CatalogNumberWithLogo
                            catalogNo={row.catalogNumber}
                            brand={row.brand.name}
                          />
                        </>
                      </TableCol>,
                      <TableCol key="name" span={5}>
                        <TyrePropertiesWithIcons
                          name={row.name}
                          noiseLevel={row.noiseLevel}
                          wetPerformance={row.wetPerformance}
                          rollingResistance={row.rollingResistance}
                          design={row.design}
                          labelUrl={`https://www.contimediacenter.com/eulabel/en/${row.catalogNumber}/`}
                        />
                      </TableCol>,
                      <TableCol key="season" span={1}>
                        <SeasonIcon
                          season={this.getSeasonIcon(row.season.name)}
                        />
                      </TableCol>,
                      <TableCol align="center" key="price" span={2}>
                        {formatPriceWithCurrency(row.grossPrice)}
                      </TableCol>,
                      <TableCol align="center" key="mail" span={1}>
                        <EmailButton onClick={this.emailClicked} id={row._id} />
                      </TableCol>,
                      <TableCol span={2} key="cart">
                        <AddToCart
                          id={row._id}
                          value={row.cartQuantity}
                          stock={row.quantity}
                          onQuantityChange={this.onQuantityChange}
                          deliveryDate={row.deliveryDate}
                          onAddToCart={this.onAddToCart}
                          addToCartLoading={addToCartLoadingId === row._id}
                        />
                      </TableCol>,
                    ]}
                  </TableRow>
                ))
              }
            </Table>
          )}
        </InfiniteList>
      </SearchContent>
    );
  }
}

export default connect((state) => ({
  search: state.search.search,
  seasons: state.seasons.seasons,
  brand: state.brand.brand,
  vehicleMake: state.vehicleModel.vehicleMake,
  vehicleModel: state.vehicleModel.vehicleModel,
  chassis: state.chassis.chassis,
}))(withRouter(withTranslation()(SearchContainer)));
